#references {
    color: var(--color-bg);
    text-align: center;
}

.header__references {
    margin: 0 0 2rem;
}

.header__references h1 {
    font-size: xxx-large;
}

.second__header {
    font-size: x-large;
}

.videos {
    display: flex;
    justify-content: center;
    position: relative;
}

.videos iframe,
.videos object,
.videos embed {
    top: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
}

a:hover {
    color: var(--color-bg);
}


/* ================== MEDIA QUERIES (SMALL DEVICES) ===============*/

@media screen and (max-width:1024px) {
    .videos {
        display: block;
    }
    .video {
        width: 100%;
        aspect-ratio: 16/9;
    }
}