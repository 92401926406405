header {
    overflow: hidden;
    height: 100vh;
}

.header__container {
    padding-top: 40vh;
    text-align: center;
    height: 100%;
    position: relative;
}

.header__container h1 {
    font-size: 3.5rem;
}

.header__container h3 {
    font-size: 2rem;
}

.container.logo h2 {
    position: absolute;
    margin-left: 0;
    top: 0.5rem;
    font-size: 2.5rem;
    font-weight: 800;
}


/* ============ CTA==========*/

.cta-button {
    font-size: small;
    font-weight: 500;
}

.cta {
    position: absolute;
    top: 0;
    right: 0;
    top: 1rem;
    justify-content: center;
    color: var(--color-bg);
}


/*===========HEADER SOCIALS ====================*/

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    color: var(--color-white);
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-white);
}


/* ==============ME============= */


/* ==============SCROLL DOWN============= */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

.video__background {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.filterGrid {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    background-image: url(../../assets/grid.png);
    opacity: 0.8;
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.video-thumb {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: opacity 400ms ease 0ms;
    z-index: -2;
}

.tiny {
    filter: blur(20px);
    transform: scale(1.1);
    transition: visibility 0ms ease 400ms;
}

.play__button {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    right: 17rem;
    top: 1.8rem;
}

.video__background video::-webkit-media-controls {
    opacity: 0;
}


/* ================== MEDIA QUERIES (MEDIUM DEVICES) ===============*/

@media screen and (max-width:1024px) {
    header {
        height: 100vh;
    }
}


/* ================== MEDIA QUERIES (SMALL DEVICES) ===============*/

@media screen and (max-width:600px) {
    .header__container {
        height: 100vh;
        padding-top: 37vh;
    }
    .header__socials,
    .scroll__down {
        display: none;
    }
    .header__container h1 {
        font-size: 11.66vw;
    }
    .header__container h3 {
        font-size: 6vw
    }
    .filterGrid {
        opacity: 0.3;
    }
    .play__button {
        right: 1rem;
        top: 1rem;
    }
}