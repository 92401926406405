#impressum {
    color: var(--color-bg);
}

.container.logo1 h2 {
    color: var(--color-bg);
    position: absolute;
    margin-left: 0;
    top: 1rem;
    font-size: 2.5rem;
    font-weight: 800;
}

.container.logo1 h2:hover {
    color: var(--color-primary);
    transition: var(--transition);
}

.contact__information,
.identifikation {
    margin-bottom: 3rem;
}

.impressum__text {
    text-align: left;
}

.title {
    text-align: center;
    margin-bottom: 5rem;
}