#services {
    color: var(--color-bg);
    padding-top: 0.1rem;
}

.header__services {
    text-align: center;
    margin: 4rem auto;
}

.header__services h2 {
    margin-top: 2rem;
}

.headline__services {
    text-align: center;
}

.map__services {
    display: flex;
    padding-top: 4rem;
    text-align: center;
}

.step__services {
    margin: 0 2rem;
}

.services__decision {
    display: flex;
    justify-content: space-between;
    margin: 5rem 8rem 0rem;
}

.button-services {
    margin-top: 1rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
    color: var(--color-white);
}

.services__footer h2 {
    margin-top: 8rem;
    text-align: center;
}


/* ================== MEDIA QUERIES (MEDIUM DEVICES) ===============*/

@media screen and (max-width:1024px) {}


/* ================== MEDIA QUERIES (SMALL DEVICES) ===============*/

@media screen and (max-width:600px) {
    .map__services {
        flex-direction: column;
    }
    .step__services {
        margin: 0.5rem 0;
        font-size: medium;
    }
    .services__decision {
        margin: none;
        justify-content: center;
        margin: 0 2rem;
    }
    .header__services h2 {
        font-size: medium;
    }
    .header__services h1 {
        font-size: xx-large;
    }
    .services__footer h2 {
        font-size: medium;
        margin-top: 2rem;
    }
}