.drones__container {
    color: var(--color-bg);
    display: flex;
    flex-direction: column;
}

.drone__content {
    margin-top: 4rem;
}

.header__drones {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    text-align: center;
}

.drone {
    display: flex;
    align-items: top;
    justify-content: center;
}

.drone__head {
    text-align: center;
    margin-bottom: 3rem;
    font-size: xx-large;
}

img {
    border-radius: 2rem;
    min-width: 50vw;
}

.drone__text {
    margin-right: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.drone-image {
    order: 2;
}

.drone__text {
    /* justify-content: center; */
}

.capabilities {
    margin-top: 5rem;
}

.capability {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0.8rem;
}


/* ================== MEDIA QUERIES (MEDIUM DEVICES) ===============*/

@media screen and (max-width:1024px) {
    .drone {
        flex-direction: column-reverse;
    }
    .capabilities {
        margin-top: 1rem;
    }
    .drone__text {
        margin: 1rem auto;
    }
}


/* ================== MEDIA QUERIES (SMALL DEVICES) ===============*/

@media screen and (max-width:600px) {
    #drones {
        padding-top: 5rem;
    }
    .drone__text {
        align-items: center;
    }
}